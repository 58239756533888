import { useAnnotationTypesStore } from "~/composables/useAnnotationTypesStore";
import { useSystemCommentsStore } from "~/composables/useSystemCommentsStore";
import { useAppUserData } from "~/stores/useAppUserData";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) return;

  // const { initializeDictionaryData } = useSpellChecker();
  // await initializeDictionaryData();

  const { initializeAppUserData, loadUserPermissions } = useAppUserData();
  await initializeAppUserData();

  await loadUserPermissions();

  const { initializeIEWProductCategories } = useIEWProductCategories();
  await initializeIEWProductCategories();

  const { initializeLevels } = useLevels();
  await initializeLevels();

  const { initializeLessons } = useLessons();
  initializeLessons();

  const { streamComments } = useSystemCommentsStore();
  streamComments();

  const { initializeAnnotationTypes } = useAnnotationTypesStore();
  initializeAnnotationTypes();

  useRemoteConfig().activate();

  useCapabilitiesServiceStore().initialize();

  useApplicationCapabilityCategories().initialize();

  useUserDocumentsStore().initialize();

  const userClassroomsStore = useUserClassroomsDataStore();
  userClassroomsStore.initialize();

  const guidedTourStore = useGuidedTours();
  guidedTourStore.streamAllTours();

  const sampleDocumentsStore = useSampleDocuments();
  sampleDocumentsStore.streamSampleDocuments();
});
